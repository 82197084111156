
import Head from "next/head";
import { useEffect, useState } from "react";
import "../styles/globals.css";
import { SnackbarProvider } from "notistack";

function MyApp({ Component, pageProps }) {
  const [showChild, setShowChild] = useState(false);
  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }
  if (typeof window === "undefined") {
    return <></>;
  } else {
    return (
      <>

        <Head>
          <title>Techbrain Networks</title>
          <link rel="icon" href="/favicon.png" />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <meta name="description" content="Software development company based in india." />
          <meta property="og:description" content="Techbrain Networks is a leading software product development company that specializes in AI, IoT, Big data, Blockchain, cloud computing, Quantum computing and Digital Marketing." />
          <meta property="og:url" content="" />
          <meta property="og:type" content="website" />
          <link rel="" href="" />
        </Head>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3600}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
        {/* <StarsCanvas /> */}
          <Component {...pageProps} />
        </SnackbarProvider>
      </>
    );
  }
}

export default MyApp;
